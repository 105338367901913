import React from "react";

type BannerAdProps = {
    url: string;
    imageUrl: string;
    title: string;
    description: string;
    price: string;
    oldPrice: string;
    percent: string;
};

export const BannerAd: React.FC<BannerAdProps> = ({
    url,
    imageUrl,
    title,
    description,
    price,
    oldPrice,
    percent,
}) => {
    return <div>
        <a href={url} style={{textDecoration: "none"}}>
        <p style={{fontSize: "0.6rem", color: "gray"}}>REKLAMA</p>
        <div style={{background: "white", padding: "0.5rem", display: "flex", alignItems: "center", border: "1px solid #ddd", margin: "auto"}}>
            <div style={{marginRight: "15px"}}>
            <img 
                src={imageUrl} 
                alt="Zdjęcie produktu" 
                style={{maxWidth: "100px", maxHeight: "100px", objectFit: "cover", borderRadius: "5px"}}
            />
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <h2 style={{fontSize: "1rem", margin: "0 0 4px 0", lineHeight: "1.1rem"}}>
                {title}
            </h2>
            <p style={{fontSize: "0.6rem", margin: "0 0 5px 0", color: "#666"}}>
                {description}
            </p>
            <p style={{fontSize: "1.2rem", fontWeight: "bold", color: "#e91e63", margin: 0}}>
                {price}
                <span style={{color: "gray", fontSize: "0.75rem"}}>
                <s>{oldPrice}</s>
                </span><br />
                <span style={{padding: "0.2rem", backgroundColor: "rgb(204, 12, 57)", color: "white", fontSize: "0.75rem"}}>
                Oszczędzasz {percent}
                </span>
            </p>
            </div>
        </div>
        </a>
    </div>;
};

export const bannerData = [
    // {
    //     "url": "https://amzn.to/4aLj9yy",
    //     "imageUrl": "https://m.media-amazon.com/images/I/618-7FMf52L._SX522_.jpg",
    //     "title": "SONGMICS kosz na śmieci kuchenne, 3 x 8 l",
    //     "description": "kosz na śmieci, potrójny stalowy kosz na śmieci, dla małych rodzin, pedały i wiaderka, łatwy do czyszczenia, srebrny i czarny LTB24L",
    //     "price": "240,54 PLN",
    //     "oldPrice": "282,99",
    //     "percent": "15%"
    // },
];
