import styled from "styled-components";
import {
  Button,
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Box,
  Text,
} from "@chakra-ui/react";
import { Title, BigDate } from "../../components/Styled";
import gearIcon from "../../assets/gear.svg";
import {
  Calendar,
  ScheduleList,
  BuyCoffeeButton,
  Copyright,
  Sun,
  SelectAddressForm,
} from "../../components";
import { lightTheme } from "../../theme";
import { useSchedule } from "../../context/ScheduleProvider";
import Snowfall from "react-snowfall";
import { If, Then, Else } from "react-if";
import { useToday } from "../../hooks/useToday";
import { getMonthImage } from "../../helpers/helpers";
import { motion, AnimatePresence } from "framer-motion";
import React, { useState, useEffect } from 'react';

// Helper Components
const SettingsButton = ({ onClick }: any) => (
  <Button
    ml={2}
    mt={2}
    size="xs"
    color="#ffffff"
    onClick={onClick}
    backgroundColor="transparent"
  >
    <img
      src={gearIcon}
      width="16"
      height="16"
      style={{ marginRight: "0.25rem" }}
      alt="ustawienia"
    />
    Ustawienia
  </Button>
);

const MainContainer = ({ children, background }: any) => (
  <Container
    // maxW="container.md"
    overflow="visible"
    css={{
      position: "relative",
      height: "14rem",
      backgroundImage: background,
      backgroundSize: "cover",
    }}
  >
    {children}
  </Container>
);

// New modern animated loader component
const AnimatedLoaderScreen = () => {
  const messages = [
    'Wystawianie koszy...',
    'Liczenie worków...',
    'Gniecenie kartonów...',
    'Zgniatanie puszek...',
    'Segregowanie szkła...',
    'Wiązanie worków...',
    'Ładowanie śmieciarki...'
  ];
  
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  
  // Cycle through messages
  useEffect(() => {
    const messageInterval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => 
        prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex
      );
    }, 1000);
    
    return () => clearInterval(messageInterval);
  }, [messages.length]);
  
  // Update progress bar
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        // Calculate target progress based on current message
        const targetProgress = ((currentMessageIndex + 1) / messages.length) * 100;
        
        // Smoothly animate to target
        const step = (targetProgress - prevProgress) / 10;
        const newProgress = prevProgress + step;
        
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 100);
    
    return () => clearInterval(progressInterval);
  }, [currentMessageIndex, messages.length]);
  
  return (
    <Container
      minHeight="calc(100vh - 220px)"
      overflow="hidden"
      backgroundColor="#edf1f4"
      display="flex"
      flex={1}
      padding="20px 1rem 0px 1rem"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box 
        width="100%" 
        maxWidth="500px" 
        margin="80px auto 0"
        padding="1.5rem"
        borderRadius="xl" 
        bg="white" 
        boxShadow="md"
        position="relative"
        overflow="hidden"
      >
        {/* Decorative gradient blobs */}
        <Box 
          position="absolute" 
          top="-20px" 
          right="-20px" 
          width="150px" 
          height="150px" 
          borderRadius="full" 
          bg="green.50" 
          filter="blur(25px)" 
          opacity="0.7" 
          zIndex="0"
        />
        <Box 
          position="absolute" 
          bottom="-30px" 
          left="-30px" 
          width="150px" 
          height="150px" 
          borderRadius="full" 
          bg="teal.50" 
          filter="blur(25px)" 
          opacity="0.7" 
          zIndex="0"
        />
        
        {/* Truck icon with animation */}
        <Flex justify="center" mb={6} position="relative" zIndex="1">
          <Box 
            width="70px" 
            height="70px" 
            borderRadius="full" 
            bg="linear-gradient(135deg, #2B8F98 0%, #1D6369 100%)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            boxShadow="0 4px 15px rgba(43, 143, 152, 0.3)"
            position="relative"
          >
            <Box
              as={motion.div}
              position="absolute"
              inset="0"
              borderRadius="full"
              bg="linear-gradient(135deg, #2B8F98 0%, #1D6369 100%)"
              filter="blur(8px)"
              opacity="0.7"
              animate={{ 
                scale: [1, 1.2, 1],
                opacity: [0.5, 0.8, 0.5]
              }}
              transition={{
                duration: '2s',
                ease: 'easeInOut',
                repeat: 'Infinity'
              }}
            />
            <Box as="svg" width="32px" height="32px" viewBox="0 0 24 24" fill="none" color="white">
              <path d="M3 6H21V16H3V6Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7 20C8.10457 20 9 19.1046 9 18C9 16.8954 8.10457 16 7 16C5.89543 16 5 16.8954 5 18C5 19.1046 5.89543 20 7 20Z" fill="currentColor"/>
              <path d="M17 20C18.1046 20 19 19.1046 19 18C19 16.8954 18.1046 16 17 16C15.8954 16 15 16.8954 15 18C15 19.1046 15.8954 20 17 20Z" fill="currentColor"/>
            </Box>
          </Box>
        </Flex>
        
        {/* Message display with animation */}
        <Box height="40px" mb={5} textAlign="center" position="relative" zIndex="1">
          <AnimatePresence>
            <Box
              as={motion.div}
              key={currentMessageIndex}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: '0.5s' }}
              fontSize="lg"
              fontWeight="medium"
              color={lightTheme.text}
            >
              {messages[currentMessageIndex]}
            </Box>
          </AnimatePresence>
        </Box>
        
        {/* Progress bar */}
        <Box width="100%" mb={2} position="relative" zIndex="1">
          <Box 
            width="100%" 
            height="8px" 
            borderRadius="full" 
            bg="#E8EDF2"
            overflow="hidden"
          >
            <Box
              as={motion.div}
              height="100%"
              borderRadius="full"
              bg="linear-gradient(to right, #2B8F98, #1D6369)"
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: '0.3s' }}
            />
          </Box>
        </Box>
        
        {/* Progress information */}
        <Flex justifyContent="space-between" alignItems="center" mt={2} position="relative" zIndex="1">
          <Text fontSize="sm" color="gray.500">{Math.round(progress)}%</Text>
        </Flex>
        
        {/* Animated dots */}
        <Flex justify="center" mt={6} marginBottom={2} position="relative" zIndex="1">
          {[0, 1, 2].map((dot) => (
            <Box
              key={dot}
              as={motion.div}
              width="8px"
              height="8px"
              borderRadius="full"
              bg="#2B8F98"
              marginX="3px"
              animate={{
                scale: [1, 1.5, 1],
                opacity: [0.5, 1, 0.5]
              }}
              transition={{
                duration: '1.2s',
                repeat: 'Infinity', 
                delay: `${dot * 0.3}s`
              }}
            />
          ))}
        </Flex>
      </Box>
      <Copyright />
    </Container>
  );
};

const ErrorScreen = () => (
  <>
    <ErrorTemplate>
      Wystąpił błąd po stronie dostawcy danych, Przepraszamy!
    </ErrorTemplate>
    <Copyright />
  </>
);

const ScheduleContainer = ({ schedule, today }: any) => (
  <Container
    // maxW="container.md"
    overflow="hidden"
    backgroundColor="#edf1f4"
    display="flex"
    flex={1}
    flexDirection="column"
  >
    {schedule.length > 0 ? (
      <>
        <ScheduleList schedule={schedule} today={today} />
        <Copyright />
      </>
    ) : (
      <div
        style={{
          height: "100vh",
          padding: "3rem",
        }}
      >
        <SelectAddressForm />
      </div>
    )}
  </Container>
);

export function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { today, dayMonth, isDecember, isOctober, monthNumber } = useToday();
  const { isLoading, fullAddress, schedule, resetModalState, error } = useSchedule();

  const handleToggle = () => (isOpen ? onClose() : onOpen());
  const isSnowVisible = isDecember || isOctober;

  const background = `
    linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), 
    url(${getMonthImage(monthNumber)}), 
    linear-gradient(180deg, rgba(43,143,152,1) 0%, rgba(43,143,152,1) 78%, #edf1f4 78%, #edf1f4 100%)
  `;

  return (
    <>
      {isSnowVisible && <Snowfall style={{ zIndex: 1 }} />}
      <MainContainer background={background}>
        <Flex pt={4} alignItems="flex-start" justifyContent="space-between">
          <Flex flexDirection="column">
            <Title>{fullAddress}</Title>
            <BigDate>{dayMonth}</BigDate>
            <Sun />
          </Flex>
          <Flex alignItems="flex-start" justifyContent="flex-end" flexWrap="wrap">
            <BuyCoffeeButton />
            <SettingsButton onClick={handleToggle} />
          </Flex>
        </Flex>
        <Calendar schedule={schedule} today={today} />
      </MainContainer>

      <If condition={isLoading}>
        <Then>
          <If condition={!error}>
            <Then>
              <AnimatedLoaderScreen />
            </Then>
            <Else>
              <ErrorScreen />
            </Else>
          </If>
        </Then>
        <Else>
          <ScheduleContainer schedule={schedule} today={today} />
        </Else>
      </If>

      <SettingsModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          resetModalState();
        }}
      />
    </>
  );
}

const SettingsModal = ({ isOpen, onClose }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="sm">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader color={lightTheme.text}>Ustawienia</ModalHeader>
      <ModalCloseButton
        color={lightTheme.text}
        backgroundColor={lightTheme.background2}
      />
      <ModalBody>
        <SelectAddressForm />
      </ModalBody>
      <ModalFooter mt={6} justifyContent="space-between">
        <BuyCoffeeButton />
        <Button
          backgroundColor={lightTheme.background2}
          color={lightTheme.text}
          onClick={onClose}
        >
          Zamknij
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const ErrorTemplate = styled.div`
  display: flex;
  justify-content: center;
  padding: 4rem;
  font-size: 16px;
  color: ${lightTheme.red};
`;
