import React, { useMemo } from "react";
import dayjs from "dayjs";
import { Item } from "./Item";
import { Heading } from "@chakra-ui/react";
import { useTheme } from "styled-components";
import { EmptyStateTemplate } from "./EmptyStateTemplate";
import { Dayjs } from "dayjs";
import { BannerAd, bannerData } from "./AdBanner";

type ScheduleItem = {
  date: string;
  types: string[];
};

type ScheduleListProps = {
  schedule: ScheduleItem[];
  today: Dayjs;
};

// Tablica z banerami reklamowymi – można tutaj użyć zarówno zwykłego HTMLa jak i komponentów React.
const banners: React.ReactNode[] = bannerData.map((item: any, index) => item && <BannerAd 
    key={`banner-${index}`}
    url={item?.url}
    imageUrl={item.imageUrl}
    title={item.title}
    description={item.description}
    price={item.price}
    oldPrice={item.oldPrice}
    percent={item.percent}
  />);

export function ScheduleList({ schedule, today }: ScheduleListProps) {
  const theme = useTheme() as any;

  // Filtrowanie harmonogramu – pokazujemy tylko te elementy, które są od wczoraj lub później.
  const currentSchedule = useMemo(
    () =>
      schedule.filter(
        (item: ScheduleItem) =>
          dayjs.utc(item.date).diff(today, "days", true) >= -1
      ),
    [schedule, today]
  );

  const isEmpty = useMemo(() => currentSchedule.length === 0, [currentSchedule]);

  // Funkcja pomocnicza wybierająca losowy baner z tablicy
  const getRandomAd = () => {
    const randomIndex = Math.floor(Math.random() * banners.length);
    return banners[randomIndex];
  };

  // Ustalamy szansę (prawdopodobieństwo) wstawienia reklamy po danym elemencie.
  // W tym przykładzie 30% szans.
  const adInsertProbability = 0.33;

  if (isEmpty) {
    return <EmptyStateTemplate />;
  }

  // Przygotowujemy finalną listę elementów, łącząc elementy harmonogramu oraz losowo banery reklamowe.
  const elements: React.ReactNode[] = [];
  const months: string[] = [];

  currentSchedule.forEach((item: ScheduleItem, index: number) => {
    const currentMonth = dayjs.utc(item.date).format("MMMM");
    const notHaveMonth = !months.includes(currentMonth);

    // Jeśli pierwszy element danego miesiąca – dodajemy nagłówek miesiąca.
    if (notHaveMonth) {
      months.push(currentMonth);
      elements.push(
        <Heading
          key={`heading-${currentMonth}-${index}`}
          mt={6}
          color={theme.text}
          as="h4"
          size="xl"
          fontWeight={300}
        >
          {currentMonth}
        </Heading>
      );
    }

    // Dodajemy element harmonogramu
    elements.push(
      <div key={`item-${item.date}-${index}`}>
        <Item
          item={item}
          today={today}
          borderColor={theme.itemBorderColor}
          styles={{ opacity: 0.5 }}
        />
      </div>
    );

    // Jeśli nie jest to ostatni element, to z prawdopodobieństwem adInsertProbability wstawiamy baner reklamowy.
    if (index < currentSchedule.length - 1 && Math.random() < adInsertProbability) {
      elements.push(
        <div key={`ad-${index}`}>
          {getRandomAd()}
        </div>
      );
    }
  });

  return (
    <div
      key={today.toString()}
      style={{ paddingTop: "3rem", minHeight: "calc(100vh - 270px)" }}
    >
      {elements}
    </div>
  );
}
