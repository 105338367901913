import dayjs from "dayjs";
import { motion } from "framer-motion";
import { Box, Flex, Text, Badge, useColorModeValue, Wrap } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import styled, { useTheme } from "styled-components";
import { lightTheme } from "../theme";
import { GarbageIcon } from "./GarbageIcon";
import ICalendarLink from "react-icalendar-link";
import { daysLeftTo, getDaysLeftDescription } from "../helpers/helpers";
import { colors, GARBAGES, THEME } from "../helpers/constans";

type GarbageProps = {
  bgcolor: string;
  color?: string;
};

// Modern styled garbage badge
const Garbage = styled.div<GarbageProps>`
  border-radius: 50px;
  color: ${(props) => (props.color ? props.color : lightTheme.textReverse)};
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.3rem 0.7rem;
  background: ${(props) => (props.bgcolor ? props.bgcolor : "white")};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  display: inline-flex;
  align-items: center;
  margin: 0.2rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

// Styled calendar button
const AddToCalendarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.6rem;
  border-radius: 50px;
  background: linear-gradient(135deg, #2B8F98 0%, #1D6369 100%);
  color: white;
  font-weight: 500;
  font-size: 0.7rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(29, 99, 105, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 8px rgba(29, 99, 105, 0.3);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 1px 4px rgba(29, 99, 105, 0.2);
  }

  svg {
    margin-right: 0.3rem;
  }
`;

// Animation variants for Framer Motion
const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const generateEvent = (item: any, typesOfGarbage: any) => ({
  title: `Odbiór odpadów - ${typesOfGarbage
    .map((garbage: any) => GARBAGES[garbage])
    .join(", ")}`,
  description: typesOfGarbage
    .map((garbage: any) => GARBAGES[garbage])
    .join(", "),
  startTime: dayjs(item.date).hour(6).format(),
  endTime: dayjs(item.date).hour(8).format(),
  location: "",
});

// Icon container with hover effect
const IconWrapper = styled.div`
  display: inline-block;
  padding: 0.2rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.2) rotate(5deg);
  }
`;

const renderGarbageIcons = (typesOfGarbage: any) =>
  typesOfGarbage.map((garbage: any) => (
    <IconWrapper key={garbage}>
      <GarbageIcon garbage={garbage} />
    </IconWrapper>
  ));

const renderGarbageLabels = (typesOfGarbage: any) =>
  typesOfGarbage.map((garbage: any) => (
    <Garbage
      key={garbage}
      color={garbage === "PLASTIC" ? lightTheme.text : lightTheme.textReverse}
      bgcolor={colors[garbage]}
    >
      {GARBAGES[garbage]}
    </Garbage>
  ));

export const Item = ({ item, borderColor, today }: any) => {
  const daysLeft = daysLeftTo(item.date, today);
  const theme = useTheme() as any;
  const typesOfGarbage = item.types.sort((a: string, b: string) =>
    a.localeCompare(b),
  );
  const event = generateEvent(item, typesOfGarbage);
  const isUrgent = daysLeft <= 1;
  const cardBg = useColorModeValue("white", "gray.800");

  return (
    <Box
      as={motion.div}
      variants={itemVariants}
      initial="hidden"
      animate="visible"
      whileHover={{ y: -3 }}
      transition={{ duration: 0.3 } as any}
      position="relative"
      borderWidth="1px"
      backgroundColor={cardBg}
      borderColor={isUrgent ? "red.400" : borderColor}
      borderRadius={THEME.borderRadius}
      boxShadow={isUrgent ? "0 4px 15px rgba(255, 86, 48, 0.15)" : "0 3px 10px rgba(0, 0, 0, 0.05)"}
      mt="3"
      mb="3"
      overflow="hidden"
      _hover={{ boxShadow: isUrgent ? "0 6px 20px rgba(255, 86, 48, 0.2)" : "0 6px 15px rgba(0, 0, 0, 0.1)" }}
    >
      {/* Urgent indicator */}
      {isUrgent && (
        <Box
          position="absolute"
          top="0"
          right="0"
          width="80px"
          height="80px"
          bgGradient="radial(circle at 70% 30%, rgba(255, 59, 48, 0.15), transparent 70%)"
          borderTopRightRadius={THEME.borderRadius}
          zIndex="0"
        />
      )}
      
      {/* Date and garbage icons section */}
      <Flex
        p="3"
        position="relative"
        zIndex="1"
        borderBottom="1px solid"
        borderColor={useColorModeValue("gray.100", "gray.700")}
      >
        <Box flex="1">
          <Text 
            fontSize="md" 
            fontWeight="bold" 
            color={isUrgent ? "red.500" : theme.text}
          >
            {getDaysLeftDescription(daysLeft)}
          </Text>
          <Text 
            fontSize="sm" 
            color={useColorModeValue("gray.500", "gray.300")}
            mt="1"
          >
            {dayjs(item.date).format("D MMMM, dddd")}
          </Text>
          
          {isUrgent && (
            <Badge 
              mt="1" 
              colorScheme="red" 
              borderRadius="full" 
              px="2"
              py="0.5"
              fontSize="xs"
            >
              Pilne
            </Badge>
          )}
        </Box>
        
        <GarbageIconContainer>
          {renderGarbageIcons(typesOfGarbage)}
        </GarbageIconContainer>
      </Flex>
      
      {/* Garbage types section */}
      <Box p="3" pt="2">
        <Wrap spacing="0">
          {renderGarbageLabels(typesOfGarbage)}
        </Wrap>
      </Box>
      
      {/* Calendar button */}
      <Flex 
        p="2" 
        justifyContent="flex-end"
        borderTop="1px solid"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        bg={useColorModeValue("gray.50", "gray.700")}
      >
        {/* @ts-ignore */}
        <ICalendarLink event={event}>
          <AddToCalendarButton>
            <CalendarIcon mr={1} boxSize={3} /> Dodaj do kalendarza
          </AddToCalendarButton>
        </ICalendarLink>
      </Flex>
    </Box>
  );
};

const GarbageIconContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  justify-content: flex-end;
  align-items: center;
`;
