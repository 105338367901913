import { Box } from "@chakra-ui/react";
import styled from "styled-components";
import { THEME } from "../helpers/constans";
import { When } from "react-if";
import { motion } from "framer-motion";

import Truck from "../assets/garbageTruck.svg";
import TruckWithGuy from "../assets/today.svg";
import ChristmassTruckWithGuy from "../assets/lampeczki.svg";
import TruckWithLamps from "../assets/v2.svg";
import { useToday } from "../hooks/useToday";
import { Dayjs } from "dayjs";

type ScheduleItem = {
  date: string;
  types: string[];
};

type CalendarProps = {
  schedule: ScheduleItem[];
  today: Dayjs;
};

const DAYS = 7;
const days = Array(DAYS).fill(0);

export function Calendar({ schedule, today }: CalendarProps) {
  const { isDecember } = useToday();
  const dates = schedule.map((item: ScheduleItem) => item.date);

  return (
    <Box
      css={{
        marginTop: "3rem",
        position: "absolute",
        width: "calc(100% - 1rem)",
        overflow: "hidden",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        {days.map((_, index) => {
          const indexDay = today.add(index, "day");
          const formatedDay = indexDay.format("YYYY-MM-DD");
          const dayOfWeek = indexDay.format("dd");
          const dayOfWeekNumber = indexDay.format("DD");
          const hasGarbage = dates.includes(formatedDay);

          return (
            <CalendarCard 
              key={formatedDay}
              as={motion.div}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              $isToday={index === 0}
              $hasGarbage={hasGarbage}
            >
              <When condition={hasGarbage}>
                <Car
                  as={motion.div}
                  animate={{ 
                    y: [0, -2, 0],
                    rotate: [0, -3, 0]
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  <img
                    width={index === 0 ? "34px" : "32px"}
                    height="34px"
                    alt="Śmieciarka"
                    src={getTruckType(index, isDecember)}
                  />
                </Car>
              </When>
              <CardContent>
                <DayOfWeek>{dayOfWeek}</DayOfWeek>
                <DayOfWeekNumber>{dayOfWeekNumber}</DayOfWeekNumber>
                {hasGarbage && <GarbageIndicator />}
              </CardContent>
            </CalendarCard>
          );
        })}
      </Box>
    </Box>
  );
}

function getTruckType(index: number, isDecember: boolean): string {
  if (index === 0 && isDecember) {
    return ChristmassTruckWithGuy;
  } else if (index !== 0 && isDecember) {
    return TruckWithLamps;
  } else if (index === 0 && !isDecember) {
    return TruckWithGuy;
  } else {
    return Truck;
  }
}

const Car = styled.div`
  position: absolute;
  top: -25px;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
`;

const DayOfWeek = styled.div`
  color: inherit;
  font-weight: 500;
  font-size: 14px;
  opacity: 0.9;
  text-transform: uppercase;
`;

const DayOfWeekNumber = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-top: 2px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const GarbageIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #2B8F98;
  margin-top: 4px;
  box-shadow: 0 0 10px rgba(43, 143, 152, 0.4);
`;

interface CalendarCardProps {
  $isToday: boolean;
  $hasGarbage: boolean;
}

const CalendarCard = styled.div<CalendarCardProps>`
  display: flex;
  padding: 1rem;
  margin-right: 0.75rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  border-radius: ${THEME.borderRadius};
  color: ${({ theme, $isToday }) => $isToday ? theme.textReverse : theme.text};
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 70px;
  transition: all 0.3s ease;
  background: ${({ theme, $isToday, $hasGarbage }) => {
    if ($isToday) {
      return 'linear-gradient(135deg, #2B8F98 0%, #1D6369 100%)';
    }
    return $hasGarbage 
      ? `linear-gradient(to bottom, ${theme.background2}, ${theme.background2}) padding-box, linear-gradient(135deg, rgba(43, 143, 152, 0.5), rgba(29, 99, 105, 0.5)) border-box`
      : theme.background2;
  }};
  border: ${({ $hasGarbage, $isToday }) => 
    $hasGarbage && !$isToday ? '2px solid transparent' : 'none'};
  box-shadow: ${({ $isToday, $hasGarbage }) => 
    $isToday 
      ? '0 8px 20px rgba(43, 143, 152, 0.3)' 
      : $hasGarbage 
        ? '0 4px 12px rgba(0, 0, 0, 0.1)' 
        : '0 2px 6px rgba(0, 0, 0, 0.05)'};

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ $isToday, $hasGarbage }) => 
      $isToday 
        ? '0 12px 25px rgba(43, 143, 152, 0.4)' 
        : $hasGarbage 
          ? '0 8px 20px rgba(0, 0, 0, 0.15)' 
          : '0 4px 12px rgba(0, 0, 0, 0.1)'};
  }

  ${DayOfWeek} {
    color: ${({ theme, $isToday }) => $isToday ? theme.textReverse : theme.primary};
  }
`;
