type SupportedCities = 'kostrzyn' | 'pobiedziska' | 'kleszczewo' | 'swarzedz';

type City = {
    id: number;
    value: string;
};

type CityConfig = {
    lat: number;
    lng: number;
    scheduleKey: string;
    addressKey: string;
    addressIdKey: string;
    mainURL: string;
    buyButtonUrl: string;
    streetsUrl: string;
    numbersUrl: string;
    scheduleURL: string;
    cities: City[];
};

const buildAppForCity: SupportedCities = 'pobiedziska';

export const FORCE_CLEAN_DATE_STRING = "2025-02-06T01:00:00Z"; // Data wymuszonego czyszczenia harmonogramu (pobranie z api) (London timeZone)
export const LAST_CLEAN_TIMESTAMP_KEY_STRING = "lastForceCleanTimestamp";
export const SCHEDULE_EXPIRATION_MS = 14 * 24 * 60 * 60 * 1000; // 14 dni w milisekundach

export const buyButtonString = "https://buycoffee.to/janosio" as const;
const kostrzynURL = `https://zys-harmonogram.smok.net.pl/kostrzyn/2025` as const;
const pobiedziskaURL = `https://hemar-harmonogram.smok.net.pl` as const;
const kleszczewoURL = `https://zys-harmonogram.smok.net.pl/kleszczewo/2025` as const;
const swarzedzURL = `https://sepan-wroclaw.alba.com.pl/harmonogram2025_swarzedz` as const;

const globalAPIendpoint = `https://smieciarka-jedzie-cloud-api-1070671416737.europe-west3.run.app/getScheduleById` as const;
const swarzedzAPIendpoint = `https://smieciarka-jedzie-cloud-api-1070671416737.europe-west3.run.app/getScheduleByIdSwarzedz` as const;

function getBuildCodeForCity(city: SupportedCities): string {
    switch (city) {
        case 'kostrzyn':
            return "KOS";
        case 'pobiedziska':
            return "POB";
        case 'kleszczewo':
            return "KLE";
        case 'swarzedz':
            return "SWA";    
        default:
        throw new Error(`Unsupported city: ${city}`);
    }
}

function getConfigForCity(city: SupportedCities): CityConfig {
    switch (city) {
        case 'kostrzyn':
        return {
            lat: 52.3935,
            lng: 17.2298,
            scheduleKey: "kostrzyn-smieciarkajedzie_schedule",
            addressKey: "kostrzyn-smieciarkajedzie_schedule_address",
            addressIdKey: "kostrzyn-smieciarkajedzie_schedule_address_id",
            mainURL: kostrzynURL,
            buyButtonUrl: buyButtonString,
            streetsUrl: `${kostrzynURL}/addresses/streets`,
            numbersUrl: `${kostrzynURL}/addresses/numbers`,
            scheduleURL: `${globalAPIendpoint}?uri=${kostrzynURL}/reports?type=html`,
            cities: [
            {
                id: 1238,
                value: "ANTONIN"
            },
            {
                id: 1070,
                value: "BRZEŹNO"
            },
            {
                id: 1074,
                value: "BUSZKÓWIEC"
            },
            {
                id: 1244,
                value: "CHORZAŁKI"
            },
            {
                id: 1171,
                value: "CZERLEJNKO"
            },
            {
                id: 1076,
                value: "CZERLEJNO"
            },
            {
                id: 1079,
                value: "DRZĄZGOWO"
            },
            {
                id: 1080,
                value: "GLINKA DUCHOWNA"
            },
            {
                id: 1083,
                value: "GLINKA SZLACHECKA"
            },
            {
                id: 1066,
                value: "GUŁTOWY"
            },
            {
                id: 1093,
                value: "GWIAZDOWO"
            },
            {
                id: 1094,
                value: "IWNO"
            },
            {
                id: 1096,
                value: "JAGODNO"
            },
            {
                id: 1174,
                value: "KLONY"
            },
            {
                id: 1059,
                value: "KOSTRZYN"
            },
            {
                id: 1368,
                value: "LEŚNA GROBLA"
            },
            {
                id: 1369,
                value: "LIBARTOWO"
            },
            {
                id: 1137,
                value: "RUJSCA"
            },
            {
                id: 1138,
                value: "SANNIKI"
            },
            {
                id: 1139,
                value: "SIEDLEC"
            },
            {
                id: 1142,
                value: "SIEDLECZEK"
            },
            {
                id: 1145,
                value: "SIEKIERKI MAŁE"
            },
            {
                id: 1067,
                value: "SIEKIERKI WIELKIE"
            },
            {
                id: 1392,
                value: "SKAŁOWO"
            },
            {
                id: 1147,
                value: "SOKOLNIKI DRZĄZGOWSKIE"
            },
            {
                id: 1393,
                value: "SOKOLNIKI KLONOWSKIE"
            },
            {
                id: 1394,
                value: "STRUMIANY"
            },
            {
                id: 1151,
                value: "TARNOWO"
            },
            {
                id: 1152,
                value: "TRZEK"
            },
            {
                id: 881,
                value: "WIKTOROWO"
            },
            {
                id: 1161,
                value: "WRÓBLEWO"
            },
            {
                id: 1160,
                value: "WĘGIERSKIE"
            },
            {
                id: 1134,
                value: "ŁUGOWINY"
            }
            ],
        };

    case 'pobiedziska':
        return {
            lat: 52.47747,
            lng: 17.288001,
            scheduleKey: "smieciarkajedzie_schedule",
            addressKey: "smieciarkajedzie_schedule_address",
            addressIdKey: "smieciarkajedzie_schedule_address_id",
            mainURL: pobiedziskaURL,
            buyButtonUrl: buyButtonString,
            streetsUrl: `${pobiedziskaURL}/addresses/streets`,
            numbersUrl: `${pobiedziskaURL}/addresses/numbers`,
            scheduleURL: `${globalAPIendpoint}?uri=${pobiedziskaURL}/reports?type=html`,
            cities: [
            { id: 3385, value: "BOROWO-MŁYN" },
            { id: 3282, value: "BARCINEK" },
            { id: 3371, value: "BEDNARY" },
            { id: 3279, value: "BISKUPICE" },
            { id: 3307, value: "BOCINIEC" },
            { id: 3383, value: "BORÓWKO" },
            { id: 1242, value: "BUGAJ" },
            { id: 3286, value: "CZACHURKI" },
            { id: 3351, value: "GORZKIE POLE" },
            { id: 3451, value: "GOŁUNIN" },
            { id: 3378, value: "GOŁUŃ" },
            { id: 3337, value: "GÓRA" },
            { id: 3316, value: "GŁÓWIENKA" },
            { id: 3291, value: "GŁÓWNA" },
            { id: 3689, value: "JANKOWO-MŁYN" },
            { id: 3464, value: "JANKOWO" },
            { id: 3273, value: "JERZYKOWO" },
            { id: 3348, value: "JERZYN" },
            { id: 3447, value: "JEZIERCE" },
            { id: 3429, value: "KACZYNA" },
            { id: 3311, value: "KAPALICA" },
            { id: 3376, value: "KOCANOWO" },
            { id: 3313, value: "KOCIAŁKOWA GÓRKA" },
            { id: 3303, value: "KOWALSKIE" },
            { id: 3323, value: "KOŁATA" },
            { id: 3441, value: "KOŁATKA" },
            { id: 3289, value: "KRZEŚLICE" },
            { id: 3538, value: "KURACZ" },
            { id: 3325, value: "LATALICE" },
            { id: 3402, value: "NADROŻNO" },
            { id: 3570, value: "NOWA GÓRKA" },
            { id: 3274, value: "POBIEDZISKA" },
            { id: 3389, value: "PODARZEWO" },
            { id: 3482, value: "POLSKA WIEŚ" },
            { id: 3413, value: "POMARZANKI" },
            { id: 3339, value: "POMARZANOWICE" },
            { id: 3302, value: "PROMIENKO" },
            { id: 3305, value: "PROMNO" },
            { id: 3424, value: "PROMNO-STACJA" },
            { id: 3342, value: "PRUSZEWIEC" },
            { id: 3487, value: "SIODŁOWO" },
            { id: 3284, value: "STARA GÓRKA" },
            { id: 3275, value: "STĘSZEWICE" },
            { id: 3335, value: "STĘSZEWKO" },
            { id: 3288, value: "TUCZNO" },
            { id: 3277, value: "UZARZEWO-HUBY" },
            { id: 3321, value: "WAGOWO" },
            { id: 3318, value: "WRONCZYN" },
            { id: 3292, value: "WRONCZYNEK" },
            { id: 3369, value: "WÓJTOSTWO" },
            { id: 3667, value: "WĘGLEWKO" },
            { id: 3373, value: "WĘGLEWO" },
            { id: 3503, value: "ZBIERKOWO" },
            { id: 3300, value: "ZŁOTNICZKI" },
            { id: 3294, value: "ŁAGIEWNIKI" },
            ],
        };

    case 'kleszczewo':
        return {
            lat: 52.333333,
            lng: 17.173056,
            scheduleKey: "kleszczewo-smieciarkajedzie_schedule",
            addressKey: "kleszczewo-smieciarkajedzie_schedule_address",
            addressIdKey: "kleszczewo-smieciarkajedzie_schedule_address_id",
            mainURL: kleszczewoURL,
            buyButtonUrl: buyButtonString,
            streetsUrl: `${kleszczewoURL}/addresses/streets`,
            numbersUrl: `${kleszczewoURL}/addresses/numbers`,
            scheduleURL: `${globalAPIendpoint}?uri=${kleszczewoURL}/reports?type=html`,
            cities: [
            { id: 1242, value: "BUGAJ" },
            { id: 1075, value: "BYLIN" },
            {
                id: 1023,
                value: "GOWARZEWO"
            },
            {
                id: 1057,
                value: "KLESZCZEWO"
            },
            {
                id: 1099,
                value: "KOMORNIKI"
            },
            {
                id: 1132,
                value: "KREROWO"
            },
            {
                id: 1064,
                value: "KRZYŻOWNIKI"
            },
            {
                id: 1133,
                value: "LIPOWIEC"
            },
            {
                id: 1065,
                value: "MARKOWICE"
            },
            {
                id: 1062,
                value: "NAGRADOWICE"
            },
            {
                id: 1136,
                value: "POKLATKI"
            },
            {
                id: 29,
                value: "SZEWCE"
            },
            {
                id: 1149,
                value: "TANIBÓRZ"
            },
            {
                id: 1068,
                value: "TULCE"
            },
            {
                id: 1162,
                value: "ZIMIN"
            },
            {
                id: 1148,
                value: "ŚRÓDKA"
            }
        ],
        };

    case 'swarzedz':
        return {
            lat: 52.4140,
            lng: 17.0820,
            scheduleKey: "swarzedz-smieciarkajedzie_schedule",
            addressKey: "swarzedz-smieciarkajedzie_schedule_address",
            addressIdKey: "swarzedz-smieciarkajedzie_schedule_address_id",
            mainURL: swarzedzURL,
            buyButtonUrl: buyButtonString,
            streetsUrl: `${swarzedzURL}/addresses/streets`,
            numbersUrl: `${swarzedzURL}/addresses/numbers`,
            scheduleURL: `${swarzedzAPIendpoint}?uri=${swarzedzURL}/reports?type=html`,
            cities: [
            {
                id: 16580,
                value: "BOGUCIN"
            },
            {
                id: 16589,
                value: "GARBY"
            },
            {
                id: 16588,
                value: "GORTATOWO"
            },
            {
                id: 16578,
                value: "GRUSZCZYN"
            },
            {
                id: 16598,
                value: "JANIKOWO"
            },
            {
                id: 16594,
                value: "JASIN"
            },
            {
                id: 16644,
                value: "KARŁOWICE"
            },
            {
                id: 16669,
                value: "KATARZYNKI"
            },
            {
                id: 16612,
                value: "KOBYLNICA"
            },
            {
                id: 16583,
                value: "KRUSZEWNIA"
            },
            {
                id: 16602,
                value: "PACZKOWO"
            },
            {
                id: 17024,
                value: "PUSZCZYKOWO-ZABORZE"
            },
            {
                id: 16599,
                value: "RABOWICE"
            },
            {
                id: 16631,
                value: "SARBINOWO"
            },
            {
                id: 16942,
                value: "SOKOLNIKI GWIAZDOWSKIE"
            },
            {
                id: 16575,
                value: "SWARZĘDZ"
            },
            {
                id: 16720,
                value: "UZARZEWO"
            },
            {
                id: 16625,
                value: "WIERZENICA"
            },
            {
                id: 16592,
                value: "WIERZONKA"
            },
            {
                id: 16584,
                value: "ZALASEWO"
            },
            {
                id: 16635,
                value: "ŁOWĘCIN"
            }
            ],
        };  
    default:
        throw new Error(`Unsupported city: ${city}`);
    }
}

const globalConfig: CityConfig = getConfigForCity(buildAppForCity);

export const {
    lat,
    lng,
    scheduleKey,
    addressKey,
    addressIdKey,
    mainURL,
    buyButtonUrl,
    streetsUrl,
    numbersUrl,
    scheduleURL,
    cities,
}: CityConfig = globalConfig;

export const buildCode = getBuildCodeForCity(buildAppForCity);